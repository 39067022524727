.dash-list .ant-list-item {
    flex-direction: row-reverse;
    text-align: left;
}

.dash-list .ant-list-vertical .ant-list-item-extra {
    margin-left: 0px!important;
    margin-right: 40px!important;
}

.dash-list .ant-list-vertical .ant-list-item-meta-title {
    font-size: 20px !important;
    margin-bottom: 0 !important;
}

.dash-list .ant-list-vertical .ant-list-item-meta {
    margin-bottom: 10px !important;
}

.dash-stats {
    min-height: 150px;
}

.dash-list-toolbar {
    display: flex;
}

.dash-list .site-screenshot {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0 10px 20px 0px rgba(0,0,0,.1);
}

.dash-list .site-analytics-placeholder {
    height: 60px;
    border: 1px solid lightgrey;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    /* width: 900px; */
    justify-content: center;
    border-radius: 3px;
    background: linear-gradient(135deg, #f0f4ff, #ffdde1);
    color: #626262;
}