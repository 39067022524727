.ant-row.ant-form-item {
    display: flex;
}

.site-manager-content {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 24px;
    min-height: 280px;
}

.site-manager-flag {
    height: 11px;
    margin-right: 5px;
    vertical-align: initial;
}

.addSite-form {
    width: 620px;
    text-align: left;
}

.site-manager-spinner {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.has-error .ant-input-prefix,
.has-error .ant-form-explain, 
.has-error .ant-form-split {
    color: darkorange !important;
}

.has-error .ant-input-affix-wrapper .ant-input, .has-error .ant-input-affix-wrapper .ant-input:hover,
.has-error .ant-input-group-addon {
    /* color: darkorange !important; */
    border-color: darkorange !important;
}

.has-error .ant-calendar-picker-icon::after, 
.has-error .ant-time-picker-icon::after, 
.has-error .ant-picker-icon::after, 
.has-error .ant-select-arrow, 
.has-error .ant-cascader-picker-arrow,
.has-error .ant-input-group-addon {
    color: darkorange !important;
}

.settings-collapse {
    border-bottom: none !important;
}

.modal-onboarding-monthly label {
    height: 40px !important;
}