.checkout input,
.checkout textarea,
.StripeElement {
    display: block;
    max-width: 500px;
    padding: 8px 16px;
    font-size: 13px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    outline: 0;
    border-radius: 4px;
    background: white;
    border: 1px solid #cdcdcd;
}

.StripeElement {
    padding: 10px 16px !important;
}

.checkout input::placeholder {
    color: #aab7c4;
}

.checkout input:focus,
.checkout textarea:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

/* .core-optin-modal .ant-modal-body .checkout .checkout-field div {
    padding: 10px !important;
} */

.checkout {
    text-align: left;
}

.modal-error {
    line-height: 16px;
    display: block;
    font-size: 12px;
    text-align: center;
    color: rgb(255, 152, 0);
    margin-bottom: 10px;
}

.modal-error-field {
    text-align: left;
    margin: 0 0 3px 0;
}

.checkout-field-error {
    border: 1px solid rgb(255, 152, 0) !important;
}

.apply-coupon-button {
    width: 30% !important;
    font-size: 14px !important;
}