.ant-row.ant-form-item {
    display: flex;
}

.site-report-container .site-screenshot {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
}

.site-report-metrics-card {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
}

.site-report-metrics-item {
    line-height: 23px;
}

.site-report-metrics-item strong {
    min-width: 100px;
    display: inline-block;
}

.site-manager-content {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 24px;
    min-height: 280px;
}

.site-report-table-container {
    display: flex;
    flex-direction: column;
}

.site-report-table-container .site-report-table-controls {
    display: flex;
    margin: 0 0 10px 0;
    align-items: center;
}

.site-report-table-filters {
    margin-left: 15px !important;
    display: flex;
}

.site-report-table-filters .ant-row.ant-form-item {
    /* width: 100%; */
}

.report-table-row {
  height: 125px;
}

.report-table-image-container {
    text-align: center !important;
    max-width: 150px;
}

.report-table-image-container-logo {
    text-align: center !important;
    /* max-width: 150px; */
}

.report-table-name-container {
    /* min-width: 150px; */
}

.report-table-status-container {
    width: 100px;
}

.report-table-reviews-container {
    width: 100px;
}

.report-table-affId-container {
    width: 100px;
}

.report-table-links-container {
    width: 30px;
}

.report-table-image {
    max-height: 100px;
    max-width: 200px;
}

.report-table-image-logo {
    max-height: 50px;
    max-width: 150px;
}

.report-table-tag {
    font-weight: 600;
    text-transform: uppercase;
    line-height: 25px !important;
}

.report-table-row-highlighted {
    background-color: rgb(255, 243, 224);
}

/* CSS for the blurred row */
.report-table-row-blurred {
    /* /* position: relative; */
    /* background-color: rgb(255, 243, 224); */
    filter: blur(15px) grayscale(50%);
    transition: filter 0.3s ease;
    pointer-events: none;
    /* Disable interaction with the blurred content */
}


.upgrade-overlay p {
  margin-bottom: 8px;
}

.site-report-nested-table-container .ant-table {
    margin: initial !important;
}

.core-optin-modal {
   top: 10px !important;
   text-align: center;
}

/* .core-optin-modal .ant-modal-body div {
    font-size: 17px;
    padding: 10px 0;
} */

.core-optin-modal .ant-modal-header,
.core-optin-modal .ant-modal-close {
    display: none !important;
}

.core-optin-modal .ant-modal-body {
    border-top: 5px solid #ffbe02;
}

.core-optin-modal .ant-modal-content {
    border-radius: 0;
}

.core-optin-cta {
    position: relative !important;
    background-image: linear-gradient(90deg,#1ba005 0,#0ec851) !important;
    line-height: 46px !important;
    height: 100% !important;
    min-width: 160px !important;
    color: #fff!important;
    box-shadow: 0 2px 4px 0 rgba(27,160,5,.2) !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    border: none !important;
    margin-bottom: 10px;
}

.core-optin-cta-disabled {
    background-image: linear-gradient(90deg,#adadad 0,#adadad) !important;
    box-shadow: initial !important;
}

.demo-optin-cta {
    position: relative !important;
    background-image: linear-gradient(90deg,#1ba005 0,#0ec851) !important;
    line-height: 42px !important;
    height: 100% !important;
    min-width: 160px !important;
    color: #fff!important;
    box-shadow: 0 2px 4px 0 rgba(27,160,5,.2) !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    border: none !important;
    margin-bottom: 10px;
}

.unlock-full-report {
    background-image: linear-gradient(90deg,#1ba005 0,#0ec851) !important;
    color: #fff!important;
    box-shadow: 0 2px 4px 0 rgba(27,160,5,.2) !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    border: none !important;
}

/* .pdf-report-table {
    font-size: 12px;
}

.pdf-report-table-image-container {
    text-align: center !important;
    max-width: 400px;
    font-size: 12px;
}

.pdf-report-table-image {
    max-height: 100px;
    max-width: 300px;
}

.pdf-report-table-name-container {
    min-width: 200px;
    font-size: 12px;
}

.pdf-report-table-status-container {
    width: 100px;
    text-align: center !important;
    font-size: 12px;
}

.pdf-report-table-reviews-container {
    max-width: 75px;
    font-size: 12px;
}

.pdf-report-table-affId-container {
    width: 100px;
    text-align: center !important;
    font-size: 12px;
}

.pdf-report-table-links-container {
    width: 30px;
    font-size: 12px;
}

.pdf-linking-pages {
    width: 100px;
    font-size: 12px;
}

.header-container {
    display: none !important;
}

.ant-layout-footer {
    display: none !important;
}

.app-content {
    padding: 25px 25px !important;
} */

.page-list {
    text-align: left;
    height: 300px;
    overflow: auto;
    /* margin-bottom: 20px; */
}

.page-list .ant-spin-nested-loading {
    height: 100%;
}

/* .reportheader-cards div:first-child {
    margin-bottom: 10px;
}

.reportheader-cards > div {
    width: 20%; */
/* } */

/* .reportheader-cards > div:not(:first-child) {
    width: 20%;
} */

.anchor-text-table-column {
    width: 40%;
}

.anchor-text-table-column-commission {
    max-width: 50px !important;
}

.anchor-text-table-column-partner {
    max-width: 50px !important;
}

.anchor-text-table-column-button {
    max-width: 80px !important;
}

.anchor-text-table-column-link {
    max-width: 220px !important;
}