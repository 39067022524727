.report-header-demo {
    display: flex;
    /* margin: 20px 0; */
    flex-wrap: wrap;
}

@media screen and (max-width: 1280px) {

}
  
@media screen and (max-width: 950px) {
    .report-header-demo {
        display: flex;
    }
}