@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Lato:700,900&display=swap');

/* Centralized css due to lazy loading */
@import './components/Footer/Footer.css';
@import './components/Header/Header.css';
@import './components/PricingTable/PricingTable.css';

@import './modules/Account/container/Account.css';

@import './modules/Auth/Signup/Signup.css';
@import './modules/Checkout/Checkout/components/Checkout.css';
@import './modules/Dashboard/Dashboard.css';
@import './modules/Notifications/container/Notifications.css';
@import './modules/SiteManager/SiteManager.css';

@import '~react-lazy-load-image-component/src/effects/blur.css';
@import './modules/SiteReport/AlternativesTable/AlternativesTable.css';

@import './modules/SiteReport/SiteReportDemo/ReportHeaderDemo.css';
@import './modules/SiteReport/SiteReportDemo/SiteReportDemo.css';
@import './modules/SiteReport/SiteReportFull/SiteReport.css';

@font-face {
  font-family: Averta;
  src: url(./assets/fonts/Averta-Regular.woff2) format('woff2'), url(./assets/fonts/Averta-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Averta;
  src: url(./assets/fonts/Averta-Semibold.woff2) format('woff2'), url(./assets/fonts/Averta-Semibold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

body {
  margin: 0;
  font-family: Averta;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
}

.app-content {
  flex-direction: column;
  padding: 30px 3vw;
  display: flex;
  /* justify-content: center; */
  background: #fff;
  min-height: 280px;
}

.checkout-page .app-content {
    background: #fafbfb;
}

.centered-spinner {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}