.site-report-container .site-report {
    display: flex;
    justify-content: space-between;
}

.site-report-container .site-report-title {
    text-align: left;
    margin-bottom: 0;
    display: block;
}

.site-report-container .site-report-actions {
    margin-top: 10px;
}

@media screen and (max-width: 1280px) {
    .site-report-container .site-report {
        display: block;
        justify-content: initial;
    }

    .site-report-container .site-report-title {
        font-size: 20px;
    }

    .site-report-container .site-report-actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 950px) {

}

.unlock-full-report-modal {
  top: 25vh !important;
}