@import url(https://fonts.googleapis.com/css?family=Lato:700,900&display=swap);
.checkout-page .ant-layout-footer {
    display: none;
}
.header-container {
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15);
  z-index: 1;
}

.checkout-page .header-container {
    display: none;
}

.header-left {
  width: 50%;
  display: flex;
}

.header-right {
  width: 50%;
  padding-right: 60px !important;
  float: right;
  display: flex;
  justify-content: flex-end;

}

.logo {
  padding-left: 25px !important;
  margin: 0 20px;
  color: black;
}

.notify-item {
  top: 5px !important;
}

.menu-credits-point:hover {
  color: #424242 !important;
}

.header-notice {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: white;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.header-free-account-notice {
  display: flex;
  align-items: center;
}

.header-free-account-notice-text {
  margin-right: 10px;
}

@media screen and (max-width: 1280px) {
  .header-free-account-notice > .header-free-account-notice-text {
    display: none;
  }
}

@media screen and (max-width: 950px) {
  .header-notifications {
    display: none !important;
  }
  
  .header-free-account-tooltip {
    display: none !important;
  }
}

@media screen and (max-width: 1500px) {
  .header-free-account-notice-text {
    width: 165px;
    line-height: 15px;
    font-size: 13px;
    margin-right: 0px;
  }
}



.pricing {
    max-width: 1150px;
    margin: 0 auto;
}

.pricing-table {
    display: flex;
    justify-content: center;
}

.pricing-table .ant-list-item {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 21px;
}

.pricing-saving {
    background-color: #e5fde4;
    color: #0cb130;
    border-radius: 21px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    padding: 3px 10px;
    text-transform: uppercase;
    font-weight: 600;
}

.current-plan {
    background-color: #d3d3d336;
}

.pricing_grid {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 20px;
    margin: 30px 0;
}

.pricing_grid_item {
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
    padding-bottom: 24px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 7px 11px -4px rgba(0,23,62,0.2), 0 0 1px 0 #a8b9d5;
}

.pricing_grid_item.pricing_grid_item_recommended {
    border: 2px solid #2693ff!important;
    background: linear-gradient(180deg,rgba(38,147,255,.05),hsla(0,0%,100%,0)),#fff;
    margin-top: -16px;
    margin-bottom: -16px;
    /* padding-top: 49px; */
    padding-bottom: 38px;
    z-index: 1;
    border-radius: 8px;
}

.pricing_grid_item.pricing_grid_item_current {
    border: 1px solid lightgrey !important;
}

.pricing_header {
    padding: 32px 24px;
    font-size: 14px;
    text-align: center;
    border-radius: 3px 3px 0 0;
}

.pricing_grid_item.pricing_grid_item_recommended .pricing_header {
    border-radius: 6px 6px 0 0;
    /* padding: 40px 24px; */
}

.pricing_price {
    padding: 24px 24px 0;
    text-align: center;
}

.pricing_buy {
    margin: 0 0 10px 0;
}

.pricing_buy .core-optin-cta {
    padding: 10px 20px;
}

.plan_header_container {
    margin: 20px 0;
}

.pricing_features {
    padding: 0 24px 14px;
}

.pricing_feature {
    justify-content: start !important;
    min-height: 48px;
    margin: 2px 0;
    padding: 0;
    border-bottom: none;
}

.pricing_feature_text {
    border-bottom: 1px dashed #d0d7e9;
    cursor: help;
    margin-left: 10px;
    text-align: left;
}

.pricing_recommended {
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px;
    display: inline;
    padding: 4px 8px;
    margin: 0 0 0 8px;
    font-size: 12px;
    line-height: normal;
    letter-spacing: .4px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 3px;
    box-shadow: 0 7px 11px -4px rgba(0,23,62,.2), 0 0 1px 0 #a8b9d5;
    position: absolute;
    top: -12px;
    right: 20px;
    background-color: #2693ff
}

.pricing_grid .ant-list-split .ant-list-item {
    border: none;
}

@media (max-width: 992px) {
    .pricing_grid {
        grid-template-columns: 1fr !important;
    }
    
    .pricing_grid_item {
        grid-template-columns: 1fr !important;
    }
    
    .pricing_feature {
        /* justify-content: center !important; */
    }
}

@media (max-width: 1286px) {
    .pricing_grid {
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row dense;
        justify-items: center;
    }

    .pricing_features .ant-spin-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }
}

.pricing__switcher__tabs {
    text-align: center;
    border: 2px solid #f0f2f5;
    display: inline-block;
    margin: 20px auto;
    border-radius: 3px;
}

.pricing__switcher__select {
    margin: 32px auto 20px auto;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    display: block;
}

.pricing__switcher__tab {
    display: inline-block;
    background-color: #f0f2f5;
    font-size: 15px;
    font-weight: 600;
    color:  #2e2e2ecf;
    padding: 5px 25px;
    cursor: pointer;
}

.pricing__switcher__tab.active .type:before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 5px;
    background-color: #19a611;
    margin-right: 9px;
    vertical-align: top;
    margin-top: 9px;
    display: inline-block
}

.pricing__switcher__tab:last-child {
    margin-left: -4px;
}

.pricing__switcher__tab.active {
    background-color: white;
    color:  #000000;
}
.account-menuform {
    display: flex;
}

.account-form {
    margin-left: 40px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.account-form-input {
    width: 250px !important;
}

.single-form {
    padding: 24px !important;
    /* background: #fbfbfb; */
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin: 20px 0 !important;
    width: 550px;
}

.plan-details-since {
    text-align: right;
    width: 100%;
    display: block;
}

.billing-list {
    margin: 0 0 20px 0 !important;
}

.billing-list .billing-list-item {
    padding: 5px 0 !important;
}

.billing-list .billing-list-item span.billing-list-item-title {
    font-weight: 600;
    width: 110px;
    display: inline-block;
}
.signup-form {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.signup-form .signup-card {
    width: 450px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 25px 50px -10px rgba(0,0,0,.1);
    margin-bottom: 20px;
    padding: 20px;
}

.login-form-item {
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px !important;
    justify-content: space-between;
}

.login-form-item label {
    float: left;
    margin-bottom: 0 !important;
    line-height: 25px;
    font-size: 14px !important;
}

.login-form-item-actions {
    flex-direction: column;
    min-width: 400px;
}

.login-form-input {
    /* width: 300px */
}

.signup-container {
  flex-direction: row;
}

@media screen and (max-width: 940px) {
.signup-container {
    flex-direction: column;
  }
}
.checkout input,
.checkout textarea,
.StripeElement {
    display: block;
    max-width: 500px;
    padding: 8px 16px;
    font-size: 13px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    outline: 0;
    border-radius: 4px;
    background: white;
    border: 1px solid #cdcdcd;
}

.StripeElement {
    padding: 10px 16px !important;
}

.checkout input::-webkit-input-placeholder {
    color: #aab7c4;
}

.checkout input::placeholder {
    color: #aab7c4;
}

.checkout input:focus,
.checkout textarea:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

/* .core-optin-modal .ant-modal-body .checkout .checkout-field div {
    padding: 10px !important;
} */

.checkout {
    text-align: left;
}

.modal-error {
    line-height: 16px;
    display: block;
    font-size: 12px;
    text-align: center;
    color: rgb(255, 152, 0);
    margin-bottom: 10px;
}

.modal-error-field {
    text-align: left;
    margin: 0 0 3px 0;
}

.checkout-field-error {
    border: 1px solid rgb(255, 152, 0) !important;
}

.apply-coupon-button {
    width: 30% !important;
    font-size: 14px !important;
}
.dash-list .ant-list-item {
    flex-direction: row-reverse;
    text-align: left;
}

.dash-list .ant-list-vertical .ant-list-item-extra {
    margin-left: 0px!important;
    margin-right: 40px!important;
}

.dash-list .ant-list-vertical .ant-list-item-meta-title {
    font-size: 20px !important;
    margin-bottom: 0 !important;
}

.dash-list .ant-list-vertical .ant-list-item-meta {
    margin-bottom: 10px !important;
}

.dash-stats {
    min-height: 150px;
}

.dash-list-toolbar {
    display: flex;
}

.dash-list .site-screenshot {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0 10px 20px 0px rgba(0,0,0,.1);
}

.dash-list .site-analytics-placeholder {
    height: 60px;
    border: 1px solid lightgrey;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    /* width: 900px; */
    justify-content: center;
    border-radius: 3px;
    background: linear-gradient(135deg, #f0f4ff, #ffdde1);
    color: #626262;
}
.notifications-container {
    text-align: left;
}
.ant-row.ant-form-item {
    display: flex;
}

.site-manager-content {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 24px;
    min-height: 280px;
}

.site-manager-flag {
    height: 11px;
    margin-right: 5px;
    vertical-align: initial;
}

.addSite-form {
    width: 620px;
    text-align: left;
}

.site-manager-spinner {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.has-error .ant-input-prefix,
.has-error .ant-form-explain, 
.has-error .ant-form-split {
    color: darkorange !important;
}

.has-error .ant-input-affix-wrapper .ant-input, .has-error .ant-input-affix-wrapper .ant-input:hover,
.has-error .ant-input-group-addon {
    /* color: darkorange !important; */
    border-color: darkorange !important;
}

.has-error .ant-calendar-picker-icon::after, 
.has-error .ant-time-picker-icon::after, 
.has-error .ant-picker-icon::after, 
.has-error .ant-select-arrow, 
.has-error .ant-cascader-picker-arrow,
.has-error .ant-input-group-addon {
    color: darkorange !important;
}

.settings-collapse {
    border-bottom: none !important;
}

.modal-onboarding-monthly label {
    height: 40px !important;
}
.alternatives-table-card {
    padding: 10px;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    text-align: center;
    /* justify-content: space-between; */
}

.alternatives-table-card  span {
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    display: block;
}

.alternatives-table-card-item {
    margin: 5px;
}

.alternatives-table-card-item-metric {
    font-size: 13px;
    cursor: pointer;
}

.alternatives-table-card-item-value {
    font-size: 15px;
    color: #5a5a5a;
    line-height: 1.5715;
    font-weight: 600;
}

.alternatives-table-card-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.report-header-demo {
    display: flex;
    /* margin: 20px 0; */
    flex-wrap: wrap;
}

@media screen and (max-width: 1280px) {

}
  
@media screen and (max-width: 950px) {
    .report-header-demo {
        display: flex;
    }
}
.site-report-container .site-report {
    display: flex;
    justify-content: space-between;
}

.site-report-container .site-report-title {
    text-align: left;
    margin-bottom: 0;
    display: block;
}

.site-report-container .site-report-actions {
    margin-top: 10px;
}

@media screen and (max-width: 1280px) {
    .site-report-container .site-report {
        display: block;
        justify-content: initial;
    }

    .site-report-container .site-report-title {
        font-size: 20px;
    }

    .site-report-container .site-report-actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 950px) {

}

.unlock-full-report-modal {
  top: 25vh !important;
}
.ant-row.ant-form-item {
    display: flex;
}

.site-report-container .site-screenshot {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
}

.site-report-metrics-card {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
}

.site-report-metrics-item {
    line-height: 23px;
}

.site-report-metrics-item strong {
    min-width: 100px;
    display: inline-block;
}

.site-manager-content {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 24px;
    min-height: 280px;
}

.site-report-table-container {
    display: flex;
    flex-direction: column;
}

.site-report-table-container .site-report-table-controls {
    display: flex;
    margin: 0 0 10px 0;
    align-items: center;
}

.site-report-table-filters {
    margin-left: 15px !important;
    display: flex;
}

.site-report-table-filters .ant-row.ant-form-item {
    /* width: 100%; */
}

.report-table-row {
  height: 125px;
}

.report-table-image-container {
    text-align: center !important;
    max-width: 150px;
}

.report-table-image-container-logo {
    text-align: center !important;
    /* max-width: 150px; */
}

.report-table-name-container {
    /* min-width: 150px; */
}

.report-table-status-container {
    width: 100px;
}

.report-table-reviews-container {
    width: 100px;
}

.report-table-affId-container {
    width: 100px;
}

.report-table-links-container {
    width: 30px;
}

.report-table-image {
    max-height: 100px;
    max-width: 200px;
}

.report-table-image-logo {
    max-height: 50px;
    max-width: 150px;
}

.report-table-tag {
    font-weight: 600;
    text-transform: uppercase;
    line-height: 25px !important;
}

.report-table-row-highlighted {
    background-color: rgb(255, 243, 224);
}

/* CSS for the blurred row */
.report-table-row-blurred {
    /* /* position: relative; */
    /* background-color: rgb(255, 243, 224); */
    -webkit-filter: blur(15px) grayscale(50%);
            filter: blur(15px) grayscale(50%);
    transition: -webkit-filter 0.3s ease;
    transition: filter 0.3s ease;
    transition: filter 0.3s ease, -webkit-filter 0.3s ease;
    pointer-events: none;
    /* Disable interaction with the blurred content */
}


.upgrade-overlay p {
  margin-bottom: 8px;
}

.site-report-nested-table-container .ant-table {
    margin: initial !important;
}

.core-optin-modal {
   top: 10px !important;
   text-align: center;
}

/* .core-optin-modal .ant-modal-body div {
    font-size: 17px;
    padding: 10px 0;
} */

.core-optin-modal .ant-modal-header,
.core-optin-modal .ant-modal-close {
    display: none !important;
}

.core-optin-modal .ant-modal-body {
    border-top: 5px solid #ffbe02;
}

.core-optin-modal .ant-modal-content {
    border-radius: 0;
}

.core-optin-cta {
    position: relative !important;
    background-image: linear-gradient(90deg,#1ba005 0,#0ec851) !important;
    line-height: 46px !important;
    height: 100% !important;
    min-width: 160px !important;
    color: #fff!important;
    box-shadow: 0 2px 4px 0 rgba(27,160,5,.2) !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    border: none !important;
    margin-bottom: 10px;
}

.core-optin-cta-disabled {
    background-image: linear-gradient(90deg,#adadad 0,#adadad) !important;
    box-shadow: initial !important;
}

.demo-optin-cta {
    position: relative !important;
    background-image: linear-gradient(90deg,#1ba005 0,#0ec851) !important;
    line-height: 42px !important;
    height: 100% !important;
    min-width: 160px !important;
    color: #fff!important;
    box-shadow: 0 2px 4px 0 rgba(27,160,5,.2) !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    border: none !important;
    margin-bottom: 10px;
}

.unlock-full-report {
    background-image: linear-gradient(90deg,#1ba005 0,#0ec851) !important;
    color: #fff!important;
    box-shadow: 0 2px 4px 0 rgba(27,160,5,.2) !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    border: none !important;
}

/* .pdf-report-table {
    font-size: 12px;
}

.pdf-report-table-image-container {
    text-align: center !important;
    max-width: 400px;
    font-size: 12px;
}

.pdf-report-table-image {
    max-height: 100px;
    max-width: 300px;
}

.pdf-report-table-name-container {
    min-width: 200px;
    font-size: 12px;
}

.pdf-report-table-status-container {
    width: 100px;
    text-align: center !important;
    font-size: 12px;
}

.pdf-report-table-reviews-container {
    max-width: 75px;
    font-size: 12px;
}

.pdf-report-table-affId-container {
    width: 100px;
    text-align: center !important;
    font-size: 12px;
}

.pdf-report-table-links-container {
    width: 30px;
    font-size: 12px;
}

.pdf-linking-pages {
    width: 100px;
    font-size: 12px;
}

.header-container {
    display: none !important;
}

.ant-layout-footer {
    display: none !important;
}

.app-content {
    padding: 25px 25px !important;
} */

.page-list {
    text-align: left;
    height: 300px;
    overflow: auto;
    /* margin-bottom: 20px; */
}

.page-list .ant-spin-nested-loading {
    height: 100%;
}

/* .reportheader-cards div:first-child {
    margin-bottom: 10px;
}

.reportheader-cards > div {
    width: 20%; */
/* } */

/* .reportheader-cards > div:not(:first-child) {
    width: 20%;
} */

.anchor-text-table-column {
    width: 40%;
}

.anchor-text-table-column-commission {
    max-width: 50px !important;
}

.anchor-text-table-column-partner {
    max-width: 50px !important;
}

.anchor-text-table-column-button {
    max-width: 80px !important;
}

.anchor-text-table-column-link {
    max-width: 220px !important;
}
/* Centralized css due to lazy loading */

@font-face {
  font-family: Averta;
  src: url(/static/media/Averta-Regular.fcade588.woff2) format('woff2'), url(/static/media/Averta-Regular.584c30db.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Averta;
  src: url(/static/media/Averta-Semibold.2d2964cc.woff2) format('woff2'), url(/static/media/Averta-Semibold.e5b2df3e.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

body {
  margin: 0;
  font-family: Averta;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
}

.app-content {
  flex-direction: column;
  padding: 30px 3vw;
  display: flex;
  /* justify-content: center; */
  background: #fff;
  min-height: 280px;
}

.checkout-page .app-content {
    background: #fafbfb;
}

.centered-spinner {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
