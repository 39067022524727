.alternatives-table-card {
    padding: 10px;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    text-align: center;
    /* justify-content: space-between; */
}

.alternatives-table-card  span {
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    display: block;
}

.alternatives-table-card-item {
    margin: 5px;
}

.alternatives-table-card-item-metric {
    font-size: 13px;
    cursor: pointer;
}

.alternatives-table-card-item-value {
    font-size: 15px;
    color: #5a5a5a;
    line-height: 1.5715;
    font-weight: 600;
}

.alternatives-table-card-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}